<template>
  <div class="page">
    <el-result icon="info" title="Not Found" subTitle="点击按钮返回首页">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="goHome">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.$router.replace('/');
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}
</style>